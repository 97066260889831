.kb-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .kb-main {
    display: flex;
    flex: 1;
  }
  
  @media (max-width: 768px) {
    .kb-main {
      flex-direction: column;
    }
  }