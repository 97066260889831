.subscribe {
    margin-top: 10px;
    align-content: center;
    justify-content: center;
  }
  
  .subscribe input {
    padding: 0.5rem;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .subscribe button {
    padding: 0.5rem;
    background-color: #6e5699;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .subscribe button:hover {
    background-color: #5a4780;
  }
  