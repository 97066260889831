/* wiki.css */

.app-wiki {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header-wiki {
  background-color: #6e5699;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-wiki h1 {
  color: #fff;
}

.search-bar-wiki {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
}

@media (max-width: 768px) {
  .search-bar-wiki {
    display: none;
  }
  
  .menu-toggle {
    display: block;
  }
  
  .left-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  
  .left-nav.open {
    transform: translateX(0);
  }
}

.main-content-wiki {
  display: flex;
  flex-grow: 1;
  padding-left: 1rem;
  padding-right: 1rem;
}

.left-nav {
  width: 250px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 1rem;
  transition: transform 0.3s ease;
}

.left-nav ul {
  list-style-type: none;
  padding: 0;
}

.left-nav li {
  margin-bottom: 0.5rem;
}

.icon {
  margin-right: 0.5rem;
}

main {
  flex-grow: 1;
  padding: 1rem;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card h3 {
  padding: 0.5rem;
  margin: 0;
}

.tags {
  padding: 0.5rem;
}

.tag {
  background-color: #e0e0e0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.footer {
  background-color: #1b1717;
  padding: 1rem;
  text-align: center;
}

.footer p {
  color: #fff;
}

.footer nav a {
  margin: 0 0.5rem;
  color: #f8f7f7;
  text-decoration: none;
}

.footer nav a:hover {
  text-decoration: underline;
}

/* Content Section Styles */
.content-section {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-section h1,
.content-section h2,
.content-section h3,
.content-section h4,
.content-section h5,
.content-section h6 {
  color: #333;
  margin-top: 1rem;
}

.content-section p {
  color: #555;
  line-height: 1.6;
  margin-top: 0.5rem;
}

.content-section ul {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}

.content-section ul li {
  margin-bottom: 0.5rem;
}

/* Divider */
.divider {
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
