.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    height: 30px;
  }
  
  .top-bar nav ul {
    display: flex;
    list-style-type: none;
  }
  
  .top-bar nav ul li {
    margin-right: 20px;
  }
  
  .auth-buttons button {
    margin-left: 10px;
  }
  
  .contact-us-btn {
    background-color: #0a95ff;
    color: white;
  }
  
  @media (max-width: 768px) {
    .top-bar nav, .auth-buttons {
      display: none;
    }
    
    .menu-icon {
      display: block;
    }
  }