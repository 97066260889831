.right-bar {
    width: 300px;
    padding: 20px;
  }
  
  .stay-updated {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .right-bar {
      width: 100%;
    }
  }