body, html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: #333;
  }
  
  .app-container {
    display: flex;
    justify-content: space-between;
    max-width: 960px;
    margin: 0 auto;
    padding: 20px 10px;
  }
  
  .main-content {
    flex: 1;
    max-width: 600px;
    padding-right: 20px;
  }

  .side-menu-header {
    margin-bottom: 10px;
    font-size: 13px;  /* Adjust as needed */
  }
  
  .side-menu {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 12px;
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .side-menu a {
    margin-bottom: 5px;
    text-decoration: none;
    color: #0000FF;
  }
  
  /* .side-menu a {
    color: #0000FF;
    text-decoration: none;
    margin-bottom: 5px;
  } */
  
  .side-menu a:hover {
    text-decoration: underline;
  }
  
  .profile-image {
    max-width: 150px;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  
  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  p, ul {
    margin-bottom: 10px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  a {
    color: #0000FF;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .app-container {
      flex-direction: column-reverse;
    }
  
    .main-content {
      padding-right: 0;
    }

    .menu-toggle {
        display: block;
    }

    .side-menu {
      position: fixed;
      top: 10px;
      right: 10px;
      width: auto;
      z-index: 1000;
    }
  
    .menu-toggle {
      display: block;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }
  
    .menu-items {
      display: none;
      position: fixed;
      bottom: 10px;
      right: 10px;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 10px;
      z-index: 1000;
      flex-direction: column;
      align-items: flex-end;
      box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    }
  
    .side-menu.open .menu-items {
      display: flex;
    }
  
    .side-menu a {
      margin-bottom: 10px;
      white-space: nowrap;
    }
  
    .side-menu a:last-child {
      margin-bottom: 0;
    }
  
    /* .side-menu {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  
    .side-menu a {
      margin-left: 10px;
      margin-bottom: 5px;
    } */
  }
  .notes-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .notes-container form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .notes-container textarea {
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .notes-container button {
    padding: 10px;
    background-color: #0000FF;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .notes-list {
    list-style-type: none;
    padding: 0;
  }
  
  .notes-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .strike-through {
    text-decoration: line-through;
  }
  
  .notes-list button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    cursor: pointer;
  }  

  .notes-link {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    color: #333;
    text-decoration: none;
    border-radius: 3px;
  }
  
  .notes-link:hover {
    background-color: #e0e0e0;
  }

  .notes-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .notes-container form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .notes-container textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .notes-container button[type="submit"] {
    padding: 10px;
    background-color: #0000FF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .notes-list {
    list-style-type: none;
    padding: 0;
  }
  
  .notes-list li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .notes-list li:last-child {
    border-bottom: none;
  }
  
  .notes-list input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .note-content {
    flex-grow: 1;
    word-break: break-word;
  }
  
  .strike-through .note-content {
    text-decoration: line-through;
    color: #888;
  }
  
  .delete-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
  }
  
  .delete-btn svg {
    width: 20px;
    height: 20px;
    fill: #888;
  }
  
  .delete-btn:hover svg {
    fill: #ff4444;
  }
  
  .notes-list a {
    color: #0000FF;
    text-decoration: none;
  }
  
  .notes-list a:hover {
    text-decoration: underline;
  }

  .note-content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }
  
  .note-content {
    word-break: break-word;
  }
  
  .note-timestamp {
    font-size: 0.8em;
    color: #888;
    margin-top: 4px;
  }

  /* Add this to your existing CSS */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #0000FF;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    margin: 0 10px;
  }

  .secrets-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .secrets-container form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .secrets-container input,
  .secrets-container textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .secrets-container button[type="submit"] {
    padding: 10px;
    background-color: #0000FF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .secrets-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .secrets-table th,
  .secrets-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .secrets-table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .secret-timestamp {
    display: block;
    font-size: 0.8em;
    color: #888;
    margin-top: 4px;
  }
  
  .secrets-table button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 1.2em;
    margin-right: 5px;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #0000FF;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    margin: 0 10px;
  }
  
  @media (max-width: 600px) {
    .secrets-table {
      font-size: 14px;
    }
  
    .secrets-table th,
    .secrets-table td {
      padding: 5px;
    }
  
    .secrets-table button {
      font-size: 1em;
      padding: 3px;
    }
  }