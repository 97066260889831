.fh-profile {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #282829;
    background-color: #fff;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #dee0e1;
  }
  
  .logo {
    color: #b92b27;
    font-weight: bold;
    font-size: 22px;
  }
  
  .sign-in {
    background-color: #b92b27;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
  }
  
  .profile-container {
    display: flex;
    max-width: 1002px;
    margin: 0 auto;
    padding: 16px;
  }
  
  .profile-left {
    flex: 2;
    margin-right: 32px;
  }
  
  .profile-right {
    flex: 1;
  }
  
  .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 8px;
  }
  
  h1 {
    font-size: 23px;
    font-weight: bold;
    margin: 0 0 4px 0;
  }
  
  .follower-info {
    font-size: 13px;
    color: #636466;
    margin: 0 0 16px 0;
  }

  .user-description {
    font-size: 13px;
    color: #282829;
    margin: 0 0 16px 0;
    line-height: 1.4;
    max-width: 600px;
  }
  
  .profile-tabs {
    display: flex;
    border-bottom: 1px solid #dee0e1;
    margin-bottom: 16px;
  }
  
  .profile-tabs a {
    padding: 12px 16px;
    color: #636466;
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
  }
  
  .profile-tabs a.active {
    border-bottom: 2px solid #b92b27;
    color: #b92b27;
  }
  
  .profile-content h2 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  
  .profile-item, .question-item {
    display: flex;
    margin-bottom: 16px;
  }
  
  .profile-item img, .question-item img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .profile-item h3, .question-item h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 2px 0;
  }
  
  .profile-item p, .question-item p {
    font-size: 13px;
    color: #636466;
    margin: 0;
  }
  
  .question-item h4 {
    font-size: 15px;
    font-weight: bold;
    margin: 8px 0;
  }
  
  .more-link {
    color: #2b6dad;
    cursor: pointer;
  }
  
  .credentials, .knows-about {
    background-color: #f7f7f8;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 3px;
  }
  
  .credentials h2, .knows-about h2 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  
  .credentials ul, .knows-about ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .credentials li, .knows-about li {
    margin-bottom: 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
  
  .credentials li svg, .knows-about li svg {
    margin-right: 8px;
    color: #636466;
  }

  /* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .profile-container {
      flex-direction: column;
      padding: 16px 12px;
    }
  
    .profile-left, .profile-right {
      width: 100%;
      margin-right: 0;
    }
  
    .profile-image {
      width: 80px;
      height: 80px;
      margin-bottom: 8px;
    }
  
    h1 {
      font-size: 20px;
    }
  
    .user-title, .follower-info, .user-description {
      font-size: 14px;
    }
  
    .profile-tabs {
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 8px;
    }
  
    .profile-tabs a {
      padding: 12px 12px;
      font-size: 12px;
    }
  
    .credentials, .knows-about {
      margin-top: 16px;
    }
  
    .profile-item, .question-item {
      flex-direction: column;
    }
  
    .profile-item img, .question-item img {
      margin-bottom: 8px;
    }
  }