.body-content {
    flex: 1;
    padding: 20px;
  }
  
  .featured-articles {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .featured-articles article {
    flex: 1 1 300px;
  }
  
  .featured-articles img {
    width: 100%;
    height: auto;
  }