.side-bar {
    width: 250px;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .featured-guide {
    background-color: #e1ecf4;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .side-bar nav ul {
    list-style-type: none;
    padding-left: 20px;
  }
  
  @media (max-width: 768px) {
    .side-bar {
      width: 100%;
    }
  }