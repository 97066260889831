/* JobSearch.css */

.job-search-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #faf8f5;
    min-height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: #e86c00;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .search-input, .dropdown, .search-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-input input, .dropdown select {
    width: 100%;
    border: none;
    background: transparent;
    font-size: 16px;
  }
  
  .search-button {
    background-color: #e86c00;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-button:hover {
    background-color: #d86000;
  }
  
  .job-categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
  
  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  /* .mobile-icon { background-image: url('/images/mobile-applications.svg'); } */
  /* .mobile-icon {
    background-image: url('/images/mobile-applications.svg');
  } */
  /* .frontend-icon { background-image: url('/images/frontend-developers.svg'); }
  .backend-icon { background-image: url('/images/backend.svg'); }
  .devops-icon { background-image: url('/images/devops.svg'); }
  .emerging-icon { background-image: url('/images/backend.svg'); }
  .analytics-icon { background-image: url('/images/analytics.svg'); } */
  
  @media (max-width: 600px) {
    .job-categories {
      grid-template-columns: repeat(2, 1fr);
    }
  }